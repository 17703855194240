<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>商品列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="商品名称">
                                <el-input v-model="search.title"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="发布状态">
                                <el-select v-model="search.publish_state" placeholder="请选择类型" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="已发布" :value=1></el-option>
                                    <el-option label="未发布" :value=0></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="审核状态">
                                <el-select v-model="search.examine_status" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="审核中" :value="0"></el-option>
                                    <el-option label="审核通过" :value="1"></el-option>
                                    <el-option label="审核失败" :value="2"></el-option>
                                    <el-option label="违规下架" :value="3"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="运动品类">
                                <el-input v-model="search.motion_category"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="运动效果">
                                <el-input v-model="search.target_category"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="有效期至">
                                <el-date-picker style="width: 100%" v-model="searchtime" type="daterange"
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                    format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button icon="el-icon-plus" size="medium" type="primary" @click="tosee()">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column prop="title" label="商品名称" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="coupon_activity_qrcode" label="商品二维码" width="100">
                    <template slot-scope="scope">
                        <viewer v-if="!is_empty(scope.row.commodity_qrcode)">
                            <el-avatar shape="square" :size="22" :src="scope.row.commodity_qrcode"></el-avatar>
                        </viewer>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="store_uuid" label="适用门店" width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :key="item.index" v-for="item in scope.row.store_uuid_all" class="form-tag_colour-tag"
                            type="info" size="mini">
                            {{ item.store_name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="tag" label="运动品类" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :key="item.id" v-for="item in scope.row.motion_category" class="form-tag_colour-tag"
                            effect="dark" size="mini">
                            {{ item.name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="tag" label="运动效果" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :key="item.id" v-for="item in scope.row.target_category" class="form-tag_colour-tag"
                            effect="dark" size="mini">
                            {{ item.name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="originally_price" label="产品原价">
                </el-table-column>
                <el-table-column prop="promotion_price" label="优惠价格">
                    <template slot-scope="scope">
                        <span>￥{{ scope.row.promotion_price }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="duration" label="时长">
                    <template slot-scope="scope">
                        <span>{{ scope.row.duration }}分钟</span>
                    </template>
                </el-table-column>
                <el-table-column prop="searchtime" label="有效期至" width="120" show-overflow-tooltip>
                    <template slot-scope="scope" v-if="scope.row.expiration_time != '1970-01-01 08:33'">
                        <span>{{ scope.row.expiration_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="usage_time" label="使用时间" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="advance_booking" label="预约信息" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>提前{{ scope.row.advance_booking }}天预约</span>
                    </template>
                </el-table-column>
                <el-table-column prop="fraction_limit" label="单次最多可购买">
                    <template slot-scope="scope">
                        <span>{{ scope.row.fraction_limit }}份</span>
                    </template>
                </el-table-column>
                <el-table-column prop="stock" label="库存数量">
                </el-table-column>
                <el-table-column prop="is_refund" label="允许退款">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.is_refund === 1">是</el-tag>
                        <el-tag size="mini" v-if="scope.row.is_refund === 0" type="warning">否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="refund_remarks" label="退款备注" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="publish_state" label="发布状态">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.publish_state === 0" type="danger">未发布</el-tag>
                        <el-tag size="mini" v-if="scope.row.publish_state === 1" type="success">已发布</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="examine_status" label="审核状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.examine_status === 0">审核中</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 1" type="success">审核通过</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 2" type="danger">审核失败</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 3" type="danger">违规下架</el-tag>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="400">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('product.commodity.getinfo')" @click="tosee(scope.row.good_uuid, 'watch')"
                            size="mini">查看
                        </el-button>
                        <el-button v-if="is_auth('product.commodity.issave')" @click="tosee(scope.row.good_uuid, 'edit')"
                            size="mini">编辑
                        </el-button>
                        <el-button v-if="is_auth('product.commodity.upapply') && scope.row.publish_state === 0 &&
                            scope.row.examine_status === 1"
                            @click="operation_tip(scope.row.good_uuid, scope.row.title, 'upapply')" type="success"
                            size="mini">上架发布
                        </el-button>
                        <el-button v-if="is_auth('product.commodity.withdraw') && scope.row.publish_state === 1"
                            @click="operation_tip(scope.row.good_uuid, scope.row.title, 'withdraw')" type="warning"
                            :disabled="scope.row.lesson_state === 3" size="mini">取消发布
                        </el-button>
                        <el-button v-if="is_auth('product.commodity.isdels')"
                            @click="operation_tip(scope.row.good_uuid, scope.row.title, 'isdels')" type="danger"
                            :disabled="scope.row.is_dels > 0" size="mini">删除
                        </el-button>
                        <el-button @click="operation_tip(scope.row.examine_reason, scope.row.title, 'watch')" type="danger"
                            :disabled="scope.row.examine_status !== 3 && scope.row.examine_status !== 2" size="mini">查看原因
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '商品列表',
            loading: true,      // 加载状态
            issearch: true,    // 搜索是否展示
            search: {},         // 搜索内容
            searchtime: null,    // 时间条件
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
        }
    },
    // 创建
    created() {
        this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                title: '',
                publish_state: '',
                tag: '',
                target_category: '',
                motion_category: '',
                examine_status: "",
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "product.commodity.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0]
                postdata.endtime = this.searchtime[1]
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(good_uuid = '', title = '', operation = '') {

            let tip = ''
            let options = {}
            // 发布
            if (operation === 'upapply') {
                tip = '上架【' + title + '】？'
            }
            // 下架
            if (operation === 'withdraw') {
                tip = '<p style="text-align:center">取消发布【' + title + '】？</p><p style="color:red;text-align:center">（取消发布后，该商品信息在用户端小程序不可见哦～）</p>'
                options["dangerouslyUseHTMLString"] = true;
            }
            // 删除
            if (operation === 'isdels') {
                tip = '删除【' + title + '】？'
            }

            // 查看原因
            if (operation === 'watch') {
                tip = good_uuid
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    if (operation !== 'watch') {
                        this.isoperation(good_uuid, operation)
                    }
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(good_uuid = '', operation = '') {
            let postdata = {
                api_name: "product.commodity." + operation,
                token: this.Tool.get_l_cache('token'),
                good_uuid: good_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(good_uuid = '', type) {
            if (good_uuid === '') {
                this.$router.push({ path: '/product/commodity/edit' })
            } else {
                if(type === 'watch'){
                    this.$router.push({ path: '/product/commodity/info', query: { good_uuid: good_uuid } })
                }else{
                    this.$router.push({ path: '/product/commodity/edit', query: { good_uuid: good_uuid } })
                }
            }
        },


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
.form-tag_colour-div {
    min-height: 200px;
}
</style>
